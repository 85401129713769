import React from 'react';
import { ThemeProvider, Typography, createTheme } from '@mui/material';
import Beer from '../components/Beer';

const Home = () => {
  return (
    <>
    <Typography variant='h1' mt={2} textAlign={'center'} >Welcome to the best beer site</Typography>
    <Beer />
    </>
  );
}

export default Home;
