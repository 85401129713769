import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Grid, Link } from '@mui/material';

const Nav = () => {
return (
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            boxShadow: "0px 0px 0px "
        }}
    >
    <Grid
        container
        display="flex" justifyContent="center" alignItems="center"
        style={{
            backgroundImage: "url('../img/beerFoam.jpeg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            marginBottom: "-20px"
        }}>
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
            <Link href="/">
                <img src="../img/logo.png" alt="" width={150}/>
            </Link>
            </Grid>
        </Grid>
    </AppBar>
  </Box>
);
}
export default Nav;