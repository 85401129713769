import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Link } from "react-router-dom";
import { BeerType } from '../type/BeerType';

const Beer = () => {

    const [data, setData] = useState<BeerType[]>()
    useEffect(() => {
        axios.get("https://api.punkapi.com/v2/beers").then((res)=> setData(res.data))
    }, []);

    let linkBeer: string = '/beerMore/';

    const displayCard = data?.map((data: BeerType) =>

      <Grid item xs={12} md={3}  key={data.id} justifyContent="center" alignItems="center">
          <Card sx={{ display: 'flex', flexDirection: 'row'}} style={{ 
            color: '#ffffff', 
            backgroundImage: "url('../img/beer.jpeg')",
            backgroundSize: "100%",
            backgroundPosition: "center",
            backgroundAttachment: "fixed"
            }}>
            <Grid item xs={2} padding={3}>
              <CardMedia
                component="img"
                sx={{ height: 150, width: 40, }}
                image={data.image_url}
                alt={data.name}
              />
            </Grid>
            <Grid item xs={10}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent>
              <Typography component="div" variant="h5" align='center'>
                {data.name}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div" m={2}>
                {data.tagline}
              </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center"}}> 
              <Link style={{ 
                textDecoration: 'none', 
                backgroundColor: "#ffffff", 
                backgroundPosition: "center",
                borderRadius: "15px 50px",
                padding: "10px",
                width: "100px",
                color: "#000000",
                marginBottom: "10px" 
              }} 
                to={linkBeer + data.id}>
                <Typography>See more</Typography>
              </Link>
            </Box>
          </Box>
          </Grid>
        </Card>
      </Grid>
    )

  return (
    <>
      <Grid
        container
        direction="row"
        mt={2}
        justifyContent="center"
        alignItems="center"
        padding={1}
        spacing={3}
      >
        {displayCard}
      </Grid>
    </>
  );
}

export default Beer;