import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { CssBaseline, Grid, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    
    <CssBaseline />
    <Nav />
    <App />
    <Footer />
  </React.StrictMode>
);