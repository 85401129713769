import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { BeerType } from "../type/BeerType";
import axios from "axios";
import { Box, Chip, Grid } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const BeerMore = () => {
    const { id } = useParams();
    const [beer, setBeer] = useState<BeerType>()

    useEffect(() => {
        axios.get('https://api.punkapi.com/v2/beers/'+ id).then((res)=> setBeer(res.data?.[0]))
    }, [id]);

    const listItems = beer?.food_pairing.map((value) => (
      <ListItem
        key={value}
        disableGutters
      >
        <ListItemText primary={value} />
      </ListItem>
    ))
    
    function seeBeer (value: string){
      const idInt = id ? parseInt(id) : 0;
      const max = 24;
      const min =1;
      if(value === "previous" && min != idInt){
        const nextBeer = idInt - 1
        const beerMore = "/beerMore/" + String(nextBeer)
        return (
          <Link style={{ textDecoration: 'none', fontFamily: "FlyingBird", alignItems: "end", justifyContent: "flex-end" }} to={beerMore}>
          <Box
            sx={{
              backgroundImage: "url('../img/leftBeer.png')", 
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              width: 100,
              height: 100,
            }}
          >
          </Box>
        </Link>
        )
      }
      if(value === "next" && max != idInt){
        const nextBeer = idInt + 1
        const beerMore = "/beerMore/" + String(nextBeer)
        return (
          <Link style={{ textDecoration: 'none', fontFamily: "FlyingBird", alignItems: "end", justifyContent: "flex-end" }} to={beerMore}>
          <Box
            sx={{
              backgroundImage: "url('../img/rightBeer.png')", 
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
              width: 100,
              height: 100,
            }}
          >
          </Box>
        </Link>
        )
      }
      if(value === "ramdom"){
        const ramdom = Math.floor(Math.random() * (max - min) + min)
        const ramdomBeer =  "/beerMore/" + String(ramdom)
      return (
        <Link style={{ textDecoration: 'none', fontFamily: "FlyingBird", alignItems: "end", justifyContent: "flex-end" }} to={ramdomBeer}>
        <Box
          sx={{
            backgroundColor: "#fdc600", 
            backgroundPosition: "center",
            borderRadius: "15px 50px",
            padding: "10px",
            color: "#000000",
          }}
        ><Typography>Ramdom beer</Typography>
        </Box>
      </Link>
      )
      }
    }

return (
  <>
  <Grid container spacing={3}  direction="row" justifyContent="space-around" alignItems="center" mt={10}>
    <Grid item xs={12} md={4} style={{backgroundColor: '#ffffff', borderRadius: '15px', alignItems: 'center', alignContent: 'center', 
            backgroundImage: "url('../img/beer.jpeg')",
            backgroundSize: "100%",
            backgroundPosition: "center",
            backgroundAttachment: "fixed"}} p={2} m={2} >
    <Typography variant='h2'>{beer?.name}</Typography>
    <Typography mt={2} mb={1} style={{fontWeight: 'bold'}}>First brewed : <Chip label={beer?.first_brewed} style={{backgroundColor: '#000000', color: '#ffffff'}}/></Typography>
    <Typography mt={2} style={{fontWeight: 'bold'}}>Description :</Typography>
    <Typography ml={2} mr={2} align="center">{beer?.description}</Typography>
    <Typography mt={2} style={{fontWeight: 'bold'}}>Main beer acronyms :</Typography>
    <Typography align="center">
      ABV : <Chip label={beer?.abv} style={{backgroundColor: '#000000', marginRight: '10px', color: '#ffffff'}}/> 
      EBC : <Chip label={beer?.ebc} style={{backgroundColor: '#000000', marginRight: '10px', color: '#ffffff'}}/>
      IBU : <Chip label={beer?.ibu} style={{backgroundColor: '#000000', marginRight: '10px', color: '#ffffff'}}/>
      SMR : <Chip label={beer?.srm} style={{backgroundColor: '#000000', marginRight: '10px', color: '#ffffff'}}/>
    </Typography>
    <Typography mt={2} style={{fontWeight: 'bold'}}>Food pairing :</Typography>
    <Grid item alignItems="center">
    <List sx={{ width: '100%', maxWidth: 360 }} >
        {listItems}
      </List>
      </Grid>
    </Grid>
    <Grid item xs={6} md={1} alignItems="center" justifyContent="center">
    <img
          src={beer?.image_url}
          srcSet={beer?.image_url}
          alt={beer?.name}
          width={150}
        />
    </Grid>
  </Grid>

  <Grid container justifyContent="space-between" alignItems="center">
  <Grid item>
    {seeBeer("previous")}
    </Grid>
    <Grid item>
    {seeBeer("ramdom")}
    </Grid>
    <Grid item>
    {seeBeer("next")}
    </Grid>
  </Grid>
  </>
)
}
export default BeerMore;