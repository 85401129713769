import React from "react";
import { AppBar, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
return (
    <AppBar position="static" style={{
        backgroundColor: "rgba(0, 0, 0, 0)",
        boxShadow: "0px 0px 0px "
    }}>
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            style={{
                backgroundColor: "#442b00",
                backgroundSize: "cover",
                backgroundPosition: "center",
                paddingBottom: "50px",
                marginBottom: "0px"
            }}>
                <Grid item mt={4}>
                    <img src="../img/logo_nana.png" alt="" width={100}/>
                </Grid>
                <Grid item >
                    <Typography style={{
                        color: "#FED101",
                        marginTop: "12px"
                    }}>
                        © nanaplouf
                    </Typography>
                </Grid>
            <Grid item >
            <Link href="https://gitlab.com/nanaplouf/unicornbeer">
            <Typography style={{
                        color: "#fcfcfc",
                        marginTop: "12px"
                    }}>
                        GitLab
                    </Typography>
            </Link>
            <Link href="https://github.com/dsvera">
            <Typography style={{
                        color: "#fcfcfc",
                        marginTop: "12px"
                    }}>
                        GitHub
                    </Typography>
            </Link>
            <Link href="https://nanaplouf.fr/">
            <Typography style={{
                        color: "#fcfcfc",
                        marginTop: "12px"
                    }}>
                        Website
                    </Typography>
            </Link>
            </Grid>
        </Grid>
    </AppBar >
)
}
export default Footer;