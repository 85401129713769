import React from 'react';
import BeerMore from '../components/BeerMore';
import { Typography } from '@mui/material';



const BeerMorePage = () => {
  return (
    <>
        <BeerMore />
    </>
  );
}

export default BeerMorePage;