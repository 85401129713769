import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import BeerMorePage from './pages/BeerMorePage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/beerMore/:id' element={<BeerMorePage />} />
        <Route path='*' element={<Home />}/>
      </Routes>
    </BrowserRouter>
  );
}
export default App;
